<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        ราคาขาย / ป้ายราคา
      </h3>
      <div class="card-toolbar">
        <!-- <button
          type="button"
          class="btn btn-sm btn-light btn-success btn-hover-scale me-2"
          style="font-size: 14px; font-weight: bold"
          @click="gotoImportExcel()"
        >
          นำข้อมูลเข้าจาก Excel
        </button> -->
        <!-- <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          + เพิ่มใบตัดสต๊อก
        </button> -->
      </div>
    </div>
    <div class="card-title row ms-5 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <input
          v-model="searchInput"
          placeholder="ค้นหา"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-sm-3 mb-3">
        <select v-model="searchBy" class="form-select">
          <option value="1">ตามรหัสสินค้า</option>
          <option value="2">ตาม Barcode</option>
          <option value="3">ตาม POG</option>
        </select>
      </div>
      <div class="col-sm-1 text-end mb-4">
        <button
          @click="search()"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
      <div class="col-sm-3 mb-3">
        <select class="form-control form-select">
          <option>พิมพ์รายการที่เลือก</option>
          <option>ล้างรายการที่เลือก</option>
          <option>เลือกทั้งหมด</option>
        </select>
      </div>
      <div class="col-sm-1 text-end">
        <button
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          Action
        </button>
      </div>
    </div>

    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th>All</th>
              <th>Item code</th>
              <th>Item name</th>
              <th>Barcode</th>
              <th>POG</th>
              <th>ราคาส่ง</th>
              <th>ราคาปลีก</th>
              <th>ราคา Pro</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="9">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>{{ !showing1 ? index + 1 : showing1 + index }}</td>
              <td>{{ item.product_code }}</td>
              <td>{{ item.product_name }}</td>
              <td>{{ item.product_barcode }}</td>
              <td>{{ item.product_pog }}</td>
              <td>{{ item.product_wholesale_price }}</td>
              <td>{{ item.product_retail_price }}</td>
              <td>{{ item.product_promotion_price }}</td>

              <!-- <td
              @click="isApprove ? '' : handleApprove()"
              :style="
                isApprove
                  ? 'cursor: ; color: green'
                  : 'cursor: pointer; color: blue'
              "
            >
              {{ isApprove ? "อนุมัติ" : "รออนุมัติการเพิ่ม" }}
            </td> -->
              <td>
                <div class="row">
                  <div class="col-sm-6 d-flex justify-content-md-end">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success p-0"
                      @click="goToEdit(item.id)"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </button>
                  </div>
                  <div class="col-sm-6">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                      @click="goToPrint()"
                    >
                      <i class="bi bi-printer-fill"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    /> -->

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

// import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
// import DialogExportExcel from "./components/dialogImportExcel.vue";
import Pagination from "./../components/pagination.vue";
import Loader from "./../components/loaderIndex.vue";
import Search from "./../components/search.vue";

export default {
  components: {
    // DialogExportExcel,
    // DialogConfirmByPass,
    Pagination,
    Search,
    Loader,
  },
  setup() {
    document.title = "CHOMTHANA | ราคาขาย / ป้ายราคา";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dialogImportExcel: false,
    // dialogConfirmByPass: false,

    showing1: null,

    dataItemsShow: [],
    tableItems: [],
    itemApprove: {},

    searchInput: "",
    searchBy: "1",
    allData: [],
  }),

  created() {
    this.getAll();
  },

  methods: {
    async getAll(method) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.product.getAll(1);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.tableItems = responseData.data;
        this.loading = false;
        if (method === 1) {
          await this.$router.push({
            query: {
              page: this.$route.query.page,
              get: 1,
            },
          });
        }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    goToEdit(id) {
      this.$router.push({
        name: "WH4-edit",
        query: {
          id: id,
          editByRecive: true,
        },
      });
    },
    goToPrint() {},
    // goToNew() {
    //   this.$router.push("/warehouse/WH6-1/new");
    // },
    // gotoImportExcel() {
    //   this.$router.push("/warehouse/WH4/importexcel");
    // },
    // handleApprove() {
    //   this.dialogConfirmByPass = true;
    // },
    // submitConfirmPass() {
    //   this.isApprove = true;
    //   this.dialogConfirmByPass = false;
    // },
    // closeDialogConformByPass() {
    //   this.isApprove = false;
    //   this.dialogConfirmByPass = false;
    // },

    async search() {
      this.loading = true;
      let responseSearch = [];

      if (this.searchBy === "1") {
        responseSearch = await whApi.product.search(
          {
            searchByCode: this.searchInput,
          },
          "0"
        );
      }

      if (this.searchBy === "2") {
        responseSearch = await whApi.product.search(
          {
            searchByBarcode: this.searchInput,
          },
          "0"
        );
      }
      if (this.searchBy === "3") {
        responseSearch = await whApi.product.search(
          {
            searchByPog: this.searchInput,
          },
          "0"
        );
      }

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
  },
};
</script>
